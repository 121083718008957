import React from "react";


import './footer.css';

const Footer = () => (
  <div className="footer">
    <a className="LinkStyle" href="policy.html">Privacy Policy</a>
    &nbsp;
    <a className="LinkStyle" href="terms.html">Terms and Conditions</a>
  </div>
);

export default Footer;
