import React from 'react';
import Footer from './footer';
import badge from './app-store-badge.png';
import screenshot from './kosh_screenshot.png';
import { Helmet } from 'react-helmet';
import './App.css';

const TITLE = 'Kosh | Invisible Storage';

function App() {
  return (
    <div className="App">

      <Helmet>
        <title> { TITLE } </title>
      </Helmet>

      <div className="MainContent">

        <div className="Left-container">
            <img src={screenshot} className="Kosh-screenshot" alt="logo" />
        </div>

        <div className="Right-container">
            <p className="Kosh-title">
              Invisible storage
            </p>

            <p className="Kosh-description">
              Hide your message behind an image.<br/>
              Encrypt it with a password for ultra level of security.
            </p>

            <a href="itms-apps://itunes.apple.com/app/apple-store/id1529707748?mt=8">
              <img src={badge} className="AppStoreBadge"/>
            </a>
        </div>

      </div>

      <Footer/>

    </div>

  );
}

/*function App() {
  return (
    <div className="App">
      <header className="App-header">

        <img src={screenshot} className="Kosh-screenshot" alt="logo" />

        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}
*/
export default App;
